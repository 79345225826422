<template>
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title class="card-title-actions">
        <v-spacer />
        <vx-btn width="100" outlined color="light" @click="preview = true">Preview</vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" @click="$previous()">Cancel</vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" :loading="loading" @click="$validate(save)">
          Save
        </vx-btn>
        <vx-btn outlined color="light" class="ml-2" :loading="loading" @click="$validate(save, true)">
          Save & close
        </vx-btn>
      </v-card-title>

      <v-card-text class="pt-4">
        <v-tabs v-model="tab" :show-arrows="$vuetify.breakpoint.xs">
          <v-tab>Public info</v-tab>
          <v-tab>Private info</v-tab>
          <v-tab>Permissions</v-tab>
          <v-tab>Admin</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <Public :account="account" />
          </v-tab-item>

          <v-tab-item>
            <Private :account="account" />
          </v-tab-item>

          <v-tab-item>
            <Permission :account="account" />
          </v-tab-item>

          <v-tab-item>
            <Admin :account="account" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>

    <vx-dialog v-model="preview" :title="account._url" height="90vh" width="90%">
      <iframe :src="account._url" style="width: 100%; height: 75vh"> </iframe>
    </vx-dialog>
  </v-form>
</template>

<script>
import { Account } from '@tutti/models';
import { AccountService, MediaService } from '@tutti/services';

import Admin from './admin';
import Public from './public';
import Private from './private';
import Permission from './permission/Permissions';

export default {
  name: 'AccountEdit',

  components: {
    Admin,
    Public,
    Private,
    Permission,
  },

  data: () => ({
    tab: 0,
    account: {},
    loading: false,
    preview: false,
  }),

  created() {
    this.tab = this.$route?.params?.tab || 0;

    this.getUser();
  },

  methods: {
    async getUser() {
      const response = await AccountService.getByIdAdmin(this.$route.params.id);
      if (response) {
        this.account = new Account(response.data);
        this.account._url = `${this.artistURL}/${this.account.slug}`;
        this.$setTitle(this.account.name);
      }
    },

    async save(previous) {
      this.loading = true;

      if (this.account._file) {
        const response = await MediaService.upload(this.account._file, 'account');
        if (response) {
          this.account.media = [
            {
              name: this.account._file.name,
              key: this.account._file.key,
              original: response.data.url,
            },
          ];
        }
      }

      const account = this.account.get();
      delete account.manualEventPaymentStatusId;
      delete account.paymentStatusId;
      delete account.stripe;
      delete account.ryft;

      const response = await AccountService.patch(account);
      if (response && previous) {
        this.$previous();
      }
      this.loading = false;
    },
  },
};
</script>
