var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', {
    key: _vm.value.key,
    staticClass: "v-sheet--outlined full-width",
    attrs: {
      "disable-sort": "",
      "hide-default-footer": "",
      "headers": _vm.value.headers,
      "items": _vm.value.items,
      "item-key": _vm.value.itemKey
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref) {
        var _item$account;

        var item = _ref.item;
        return [_vm._v(" " + _vm._s((_item$account = item.account) === null || _item$account === void 0 ? void 0 : _item$account.name) + " ")];
      }
    }, {
      key: "item.role",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm._f("capitalize")(item.role.name)) + " ")];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('vx-btn', {
          attrs: {
            "text": "",
            "color": "primary",
            "loading": item._loading
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('resend', item);
            }
          }
        }, [_vm._v(" Resend ")]), _c('vx-btn', {
          attrs: {
            "text": "",
            "color": "error"
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('remove', item);
            }
          }
        }, [_vm._v(" Remove ")])];
      }
    }]),
    model: {
      value: _vm.value.selected,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "selected", $$v);
      },
      expression: "value.selected"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }