var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', {
    key: _vm.value.key,
    staticClass: "v-sheet--outlined full-width",
    attrs: {
      "disable-sort": "",
      "hide-default-footer": "",
      "headers": _vm.value.headers,
      "items": _vm.value.items,
      "item-key": _vm.value.itemKey
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm._f("userName")(item.user)) + " ")];
      }
    }, {
      key: "item.email",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.user.email) + " ")];
      }
    }, {
      key: "item.role",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm._f("capitalize")(item.role.name)) + " ")];
      }
    }, {
      key: "item.notification",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-checkbox', {
          staticClass: "checkbox d-flex justify-center",
          attrs: {
            "disabled": ""
          },
          model: {
            value: item.notification,
            callback: function callback($$v) {
              _vm.$set(item, "notification", $$v);
            },
            expression: "item.notification"
          }
        })];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('v-menu', {
          attrs: {
            "bottom": "",
            "left": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                  attrs = _ref6.attrs;
              return [_c('vx-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                }
              }, 'vx-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "color": "primary"
                }
              }, [_vm._v("mdi-dots-vertical")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.$emit('edit', item);
            }
          }
        }, [_c('v-list-item-title', [_vm._v("Edit user")])], 1), _c('v-list-item', {
          attrs: {
            "disabled": item.isOwner
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('transfer', item);
            }
          }
        }, [_c('v-list-item-title', [_vm._v("Trasfer ownership")])], 1), _c('v-list-item', {
          attrs: {
            "disabled": item.isOwner
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('remove', item);
            }
          }
        }, [_c('v-list-item-title', [_vm._v("Remove from profile")])], 1)], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.value.selected,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "selected", $$v);
      },
      expression: "value.selected"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }