var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "card-title-actions"
  }, [_c('v-spacer'), _c('vx-btn', {
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light"
    },
    on: {
      "click": function click($event) {
        _vm.preview = true;
      }
    }
  }, [_vm._v("Preview")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light"
    },
    on: {
      "click": function click($event) {
        return _vm.$previous();
      }
    }
  }, [_vm._v("Cancel")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save);
      }
    }
  }, [_vm._v(" Save ")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save, true);
      }
    }
  }, [_vm._v(" Save & close ")])], 1), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('v-tabs', {
    attrs: {
      "show-arrows": _vm.$vuetify.breakpoint.xs
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("Public info")]), _c('v-tab', [_vm._v("Private info")]), _c('v-tab', [_vm._v("Permissions")]), _c('v-tab', [_vm._v("Admin")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('Public', {
    attrs: {
      "account": _vm.account
    }
  })], 1), _c('v-tab-item', [_c('Private', {
    attrs: {
      "account": _vm.account
    }
  })], 1), _c('v-tab-item', [_c('Permission', {
    attrs: {
      "account": _vm.account
    }
  })], 1), _c('v-tab-item', [_c('Admin', {
    attrs: {
      "account": _vm.account
    }
  })], 1)], 1)], 1)], 1), _c('vx-dialog', {
    attrs: {
      "title": _vm.account._url,
      "height": "90vh",
      "width": "90%"
    },
    model: {
      value: _vm.preview,
      callback: function callback($$v) {
        _vm.preview = $$v;
      },
      expression: "preview"
    }
  }, [_c('iframe', {
    staticStyle: {
      "width": "100%",
      "height": "75vh"
    },
    attrs: {
      "src": _vm.account._url
    }
  })])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }