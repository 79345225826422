<template>
  <v-card outlined>
    <v-card-text class="mt-4">
      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="account.type"
            type="select"
            :items="ACCOUNT_TYPES"
            name="type"
            label="Profile type"
            required
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="account.userType"
            type="select"
            :items="USER_TYPES"
            name="userType"
            label="User type"
            required
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input type="tel" label="Phone" :value="account.phone" :phone.sync="account.phone" />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input v-model="account.countryCode" type="text" name="countryCode" label="Country" />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input v-model="account.website" type="text" name="website" label="Website" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="account.socialLink.facebook"
            type="url"
            name="facebook"
            label="Facebook"
            prepend-inner-icon="mdi-facebook"
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="account.socialLink.twitter"
            type="url"
            name="twitter"
            label="Twitter"
            prepend-inner-icon="mdi-twitter"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="account.socialLink.instagram"
            type="url"
            name="instagram"
            label="Instagram"
            prepend-inner-icon="mdi-instagram"
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="account.socialLink.youtube"
            type="url"
            name="youtube"
            label="YouTube"
            prepend-inner-icon="mdi-youtube"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ACCOUNT_TYPES, USER_TYPES } from '@tutti/constants';

export default {
  props: {
    account: { type: Object, required: true },
  },
  data() {
    return {
      ACCOUNT_TYPES,
      USER_TYPES,
    };
  },
};
</script>
