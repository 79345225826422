var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('section', [_vm._m(0), _c('UsersTable', {
    on: {
      "edit": _vm.addUser,
      "transfer": _vm.transferOwnership,
      "remove": _vm.removeUser
    },
    model: {
      value: _vm.dataTable.user,
      callback: function callback($$v) {
        _vm.$set(_vm.dataTable, "user", $$v);
      },
      expression: "dataTable.user"
    }
  })], 1), _c('br'), _c('section', [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "text-h6 my-auto"
  }, [_vm._v("Invitations")]), _c('div', {
    staticClass: "ml-auto my-2"
  }, [_c('vx-btn', {
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.addInvitation();
      }
    }
  }, [_vm._v("Invite user")])], 1)]), _c('InvitationsTable', {
    on: {
      "edit": _vm.addInvitation,
      "resend": _vm.resendInvitation,
      "remove": _vm.removeInvitation
    },
    model: {
      value: _vm.dataTable.invitation,
      callback: function callback($$v) {
        _vm.$set(_vm.dataTable, "invitation", $$v);
      },
      expression: "dataTable.invitation"
    }
  })], 1), _vm.dialog.user ? _c('UserDialog', {
    attrs: {
      "roles": _vm.roles,
      "user": _vm.selected.user
    },
    on: {
      "change": function change($event) {
        return _vm.getAccountUsers();
      }
    },
    model: {
      value: _vm.dialog.user,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog, "user", $$v);
      },
      expression: "dialog.user"
    }
  }) : _vm._e(), _vm.dialog.invitation ? _c('InvitationDialog', {
    attrs: {
      "roles": _vm.roles,
      "invitation": _vm.selected.invitation
    },
    on: {
      "change": function change($event) {
        return _vm.getInvitations();
      }
    },
    model: {
      value: _vm.dialog.invitation,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog, "invitation", $$v);
      },
      expression: "dialog.invitation"
    }
  }) : _vm._e(), _c('v-dialog', {
    attrs: {
      "width": "600"
    },
    model: {
      value: _vm.dialog.permissions,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog, "permissions", $$v);
      },
      expression: "dialog.permissions"
    }
  }, [_c('AboutPermissions', {
    on: {
      "close": function close($event) {
        _vm.dialog.permissions = false;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "text-h6 my-auto"
  }, [_vm._v("Users")])]);
}]

export { render, staticRenderFns }