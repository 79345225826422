<template>
  <v-dialog v-model="dialog" width="500">
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title class="py-2">Invite user</v-card-title>
        <v-card-text>
          <vx-input v-model="user.email" type="email" label="Email" required />

          <vx-input
            v-model="user.role"
            type="select"
            label="Role"
            item-text="name"
            item-value="_id"
            :items="rolesInput"
            required
          />
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <vx-btn block color="tertiary" @click="dialog = false">Cancel</vx-btn>
            </v-col>

            <v-col cols="6" sm="6" md="6">
              <vx-btn block color="primary" :loading="loading.inviteUser" @click="$validate(inviteUser)"> Save </vx-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { OnboardingService } from '@tutti/services';

export default {
  name: 'AccountAddEditEmail',
  props: {
    value: { type: Boolean, required: true },
    roles: { type: Array, required: true },
  },
  data() {
    return {
      dialog: this.value,
      user: {
        role: null,
      },
      loading: {
        inviteUser: null,
      },
      rolesInput: this.roles.filter(role => role.slug != 'owner'),
    };
  },
  watch: {
    value(newValue) {
      this.dialog = newValue;
    },
    dialog(newValue) {
      this.$emit('input', newValue);
    },
  },
  methods: {
    async inviteUser() {
      this.loading.inviteUser = true;

      const response = await OnboardingService.adminInvite(this.user, this.$route.params.id);

      if (response) {
        this.dialog = false;
        this.$emit('change');
      }

      this.loading.inviteUser = false;
    },
  },
};
</script>
