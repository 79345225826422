<template>
  <v-data-table
    :key="value.key"
    v-model="value.selected"
    class="v-sheet--outlined full-width"
    disable-sort
    hide-default-footer
    :headers="value.headers"
    :items="value.items"
    :item-key="value.itemKey"
  >
    <template v-slot:item.name="{ item }"> {{ item.account?.name }} </template>
    <template v-slot:item.role="{ item }"> {{ item.role.name | capitalize }} </template>

    <template v-slot:item.actions="{ item }">
      <vx-btn text color="primary" :loading="item._loading" @click="$emit('resend', item)"> Resend </vx-btn>
      <vx-btn text color="error" @click="$emit('remove', item)"> Remove </vx-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    value: { type: Object, required: true },
  },
};
</script>
