var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "name",
      "label": "Name",
      "required": ""
    },
    model: {
      value: _vm.account.name,
      callback: function callback($$v) {
        _vm.$set(_vm.account, "name", $$v);
      },
      expression: "account.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "slug",
      "label": "Slug",
      "required": ""
    },
    model: {
      value: _vm.account.slug,
      callback: function callback($$v) {
        _vm.$set(_vm.account, "slug", $$v);
      },
      expression: "account.slug"
    }
  })], 1), _vm.account.type == 'individual' ? [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "firstName",
      "label": "First name",
      "required": ""
    },
    model: {
      value: _vm.account.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.account, "firstName", $$v);
      },
      expression: "account.firstName"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "lastName",
      "label": "Last name",
      "required": ""
    },
    model: {
      value: _vm.account.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.account, "lastName", $$v);
      },
      expression: "account.lastName"
    }
  })], 1)] : _vm._e(), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "nearestPlace",
      "label": "Rough location",
      "placeholder": "e.g. Camden; London; UK"
    },
    model: {
      value: _vm.account.nearestPlace,
      callback: function callback($$v) {
        _vm.$set(_vm.account, "nearestPlace", $$v);
      },
      expression: "account.nearestPlace"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "color",
      "label": "Color"
    },
    model: {
      value: _vm.account.color,
      callback: function callback($$v) {
        _vm.$set(_vm.account, "color", $$v);
      },
      expression: "account.color"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "12"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "textarea",
      "label": "Short intro",
      "counter": 280,
      "rules": {
        max: 280
      }
    },
    model: {
      value: _vm.account.summary,
      callback: function callback($$v) {
        _vm.$set(_vm.account, "summary", $$v);
      },
      expression: "account.summary"
    }
  })], 1)], 2), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "2"
    }
  }, [_vm.file ? _c('vx-img', {
    attrs: {
      "square": "",
      "src": _vm.file.url
    }
  }) : _c('vx-img', {
    attrs: {
      "square": "",
      "src": _vm.$getAccountImage(_vm.account)
    }
  }), _c('input', {
    ref: "file",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "multiple": "",
      "accept": ".jpg,.jpeg,.png,.webp,.avif,.tiff,.gif,.svg",
      "onclick": "this.value = null;"
    },
    on: {
      "change": function change($event) {
        return _vm.changeFile();
      }
    }
  }), _c('vx-btn', {
    staticClass: "mt-2",
    attrs: {
      "block": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.file.click();
      }
    }
  }, [_vm._v("Replace")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "10"
    }
  }, [_c('quill-editor', {
    attrs: {
      "label": "Full biography"
    },
    model: {
      value: _vm.account.bio,
      callback: function callback($$v) {
        _vm.$set(_vm.account, "bio", $$v);
      },
      expression: "account.bio"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }