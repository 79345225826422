<template>
  <v-card outlined>
    <v-card-text class="mt-4">
      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <vx-input v-model="account.name" type="text" name="name" label="Name" required />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input v-model="account.slug" type="text" name="slug" label="Slug" required />
        </v-col>

        <template v-if="account.type == 'individual'">
          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="account.firstName" type="text" name="firstName" label="First name" required />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="account.lastName" type="text" name="lastName" label="Last name" required />
          </v-col>
        </template>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="account.nearestPlace"
            type="text"
            name="nearestPlace"
            label="Rough location"
            placeholder="e.g. Camden; London; UK"
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input v-model="account.color" type="text" name="color" label="Color" />
        </v-col>

        <v-col cols="12" sm="12" class="py-0">
          <vx-input
            v-model="account.summary"
            type="textarea"
            label="Short intro"
            :counter="280"
            :rules="{ max: 280 }"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="2">
          <vx-img v-if="file" square :src="file.url" />
          <vx-img v-else square :src="$getAccountImage(account)" />

          <input
            ref="file"
            type="file"
            class="d-none"
            multiple
            accept=".jpg,.jpeg,.png,.webp,.avif,.tiff,.gif,.svg"
            onclick="this.value = null;"
            @change="changeFile()"
          />

          <vx-btn block class="mt-2" outlined @click="$refs.file.click()">Replace</vx-btn>
        </v-col>

        <v-col cols="12" sm="10">
          <quill-editor v-model="account.bio" label="Full biography" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    account: { type: Object, required: true },
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    changeFile() {
      if (this.$refs.file) {
        [...this.$refs.file.files].forEach(file => {
          file.url = URL.createObjectURL(file);
          file.isNew = true;
          file.key = this.account.getTimestamp();
          file.accountId = this.account._id;

          this.file = file;
          this.account._file = file;
        });
      }
    },
  },
};
</script>
