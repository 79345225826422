var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "500"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "py-2"
  }, [_vm._v(_vm._s(_vm.user ? 'Edit' : 'Add') + " user")]), _c('v-card-text', [_c('vx-input', {
    attrs: {
      "type": "email",
      "label": "Email",
      "disabled": "",
      "required": ""
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('vx-input', {
    attrs: {
      "type": "select",
      "label": "Role",
      "item-text": "name",
      "item-value": "_id",
      "items": _vm.rolesInput,
      "disabled": _vm.isRoleDisabled,
      "required": ""
    },
    model: {
      value: _vm.userInput.role,
      callback: function callback($$v) {
        _vm.$set(_vm.userInput, "role", $$v);
      },
      expression: "userInput.role"
    }
  }), _c('v-row', [_c('v-col', [_c('div', [_c('b', [_vm._v("Communications")])]), _c('v-checkbox', {
    attrs: {
      "label": "System notifications"
    },
    model: {
      value: _vm.userInput.notification,
      callback: function callback($$v) {
        _vm.$set(_vm.userInput, "notification", $$v);
      },
      expression: "userInput.notification"
    }
  })], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "pt-0"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "6"
    }
  }, [_c('vx-btn', {
    attrs: {
      "block": "",
      "color": "tertiary"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "6"
    }
  }, [_c('vx-btn', {
    attrs: {
      "block": "",
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.updateUser);
      }
    }
  }, [_vm._v(" Save ")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }