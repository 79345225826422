<template>
  <v-data-table
    :key="value.key"
    v-model="value.selected"
    class="v-sheet--outlined full-width"
    disable-sort
    hide-default-footer
    :headers="value.headers"
    :items="value.items"
    :item-key="value.itemKey"
  >
    <template v-slot:item.name="{ item }"> {{ item.user | userName }} </template>
    <template v-slot:item.email="{ item }"> {{ item.user.email }} </template>
    <template v-slot:item.role="{ item }"> {{ item.role.name | capitalize }} </template>

    <template v-slot:item.notification="{ item }">
      <v-checkbox v-model="item.notification" class="checkbox d-flex justify-center" disabled />
    </template>

    <template v-slot:item.actions="{ item }">
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <vx-btn icon v-bind="attrs" v-on="on">
            <v-icon color="primary">mdi-dots-vertical</v-icon>
          </vx-btn>
        </template>

        <v-list dense>
          <v-list-item @click="$emit('edit', item)">
            <v-list-item-title>Edit user</v-list-item-title>
          </v-list-item>

          <v-list-item :disabled="item.isOwner" @click="$emit('transfer', item)">
            <v-list-item-title>Trasfer ownership</v-list-item-title>
          </v-list-item>

          <v-list-item :disabled="item.isOwner" @click="$emit('remove', item)">
            <v-list-item-title>Remove from profile</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'UsersTable',
  props: {
    value: { type: Object, required: true },
  },
};
</script>
