var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "select",
      "items": _vm.NOTIFICATION_EMAIL_TYPES,
      "name": "emailType",
      "label": "Notification Email Type"
    },
    model: {
      value: _vm.account.notification.emailType,
      callback: function callback($$v) {
        _vm.$set(_vm.account.notification, "emailType", $$v);
      },
      expression: "account.notification.emailType"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "select",
      "items": _vm.ACCOUNT_PLANS,
      "name": "plan",
      "label": "Account plan Free/Paid"
    },
    model: {
      value: _vm.account.plan,
      callback: function callback($$v) {
        _vm.$set(_vm.account, "plan", $$v);
      },
      expression: "account.plan"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "checkbox",
      "name": "Trustworthy?",
      "label": "Trustworthy?"
    },
    model: {
      value: _vm.account.isTrustworthy,
      callback: function callback($$v) {
        _vm.$set(_vm.account, "isTrustworthy", $$v);
      },
      expression: "account.isTrustworthy"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "checkbox",
      "name": "Verified?",
      "label": "Verified?"
    },
    model: {
      value: _vm.account.isVerified,
      callback: function callback($$v) {
        _vm.$set(_vm.account, "isVerified", $$v);
      },
      expression: "account.isVerified"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "checkbox",
      "name": "Sitemap?",
      "label": "Sitemap?"
    },
    model: {
      value: _vm.account.sitemap,
      callback: function callback($$v) {
        _vm.$set(_vm.account, "sitemap", $$v);
      },
      expression: "account.sitemap"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "checkbox",
      "name": "analytics",
      "label": "Display analytics in overview"
    },
    model: {
      value: _vm.account.analytics,
      callback: function callback($$v) {
        _vm.$set(_vm.account, "analytics", $$v);
      },
      expression: "account.analytics"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }