var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "select",
      "items": _vm.ACCOUNT_TYPES,
      "name": "type",
      "label": "Profile type",
      "required": ""
    },
    model: {
      value: _vm.account.type,
      callback: function callback($$v) {
        _vm.$set(_vm.account, "type", $$v);
      },
      expression: "account.type"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "select",
      "items": _vm.USER_TYPES,
      "name": "userType",
      "label": "User type",
      "required": ""
    },
    model: {
      value: _vm.account.userType,
      callback: function callback($$v) {
        _vm.$set(_vm.account, "userType", $$v);
      },
      expression: "account.userType"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "tel",
      "label": "Phone",
      "value": _vm.account.phone,
      "phone": _vm.account.phone
    },
    on: {
      "update:phone": function updatePhone($event) {
        return _vm.$set(_vm.account, "phone", $event);
      }
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "countryCode",
      "label": "Country"
    },
    model: {
      value: _vm.account.countryCode,
      callback: function callback($$v) {
        _vm.$set(_vm.account, "countryCode", $$v);
      },
      expression: "account.countryCode"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "website",
      "label": "Website"
    },
    model: {
      value: _vm.account.website,
      callback: function callback($$v) {
        _vm.$set(_vm.account, "website", $$v);
      },
      expression: "account.website"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "url",
      "name": "facebook",
      "label": "Facebook",
      "prepend-inner-icon": "mdi-facebook"
    },
    model: {
      value: _vm.account.socialLink.facebook,
      callback: function callback($$v) {
        _vm.$set(_vm.account.socialLink, "facebook", $$v);
      },
      expression: "account.socialLink.facebook"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "url",
      "name": "twitter",
      "label": "Twitter",
      "prepend-inner-icon": "mdi-twitter"
    },
    model: {
      value: _vm.account.socialLink.twitter,
      callback: function callback($$v) {
        _vm.$set(_vm.account.socialLink, "twitter", $$v);
      },
      expression: "account.socialLink.twitter"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "url",
      "name": "instagram",
      "label": "Instagram",
      "prepend-inner-icon": "mdi-instagram"
    },
    model: {
      value: _vm.account.socialLink.instagram,
      callback: function callback($$v) {
        _vm.$set(_vm.account.socialLink, "instagram", $$v);
      },
      expression: "account.socialLink.instagram"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "url",
      "name": "youtube",
      "label": "YouTube",
      "prepend-inner-icon": "mdi-youtube"
    },
    model: {
      value: _vm.account.socialLink.youtube,
      callback: function callback($$v) {
        _vm.$set(_vm.account.socialLink, "youtube", $$v);
      },
      expression: "account.socialLink.youtube"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }