<template>
  <v-card outlined>
    <v-card-text class="mt-4">
      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="account.notification.emailType"
            type="select"
            :items="NOTIFICATION_EMAIL_TYPES"
            name="emailType"
            label="Notification Email Type"
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="account.plan"
            type="select"
            :items="ACCOUNT_PLANS"
            name="plan"
            label="Account plan Free/Paid"
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input v-model="account.isTrustworthy" type="checkbox" name="Trustworthy?" label="Trustworthy?" />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input v-model="account.isVerified" type="checkbox" name="Verified?" label="Verified?" />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input v-model="account.sitemap" type="checkbox" name="Sitemap?" label="Sitemap?" />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="account.analytics"
            type="checkbox"
            name="analytics"
            label="Display analytics in overview"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { NOTIFICATION_EMAIL_TYPES, ACCOUNT_PLANS } from '@tutti/constants';

export default {
  props: {
    account: { type: Object, required: true },
  },
  data() {
    return {
      ACCOUNT_PLANS,
      NOTIFICATION_EMAIL_TYPES,
    };
  },
};
</script>
