var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', [_vm._v("Learn about permissions on Tutti "), _c('v-icon', {
    staticClass: "ml-auto",
    attrs: {
      "large": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" mdi-close")])], 1), _c('v-card-text', [_c('strong', {
    staticClass: "text-h6"
  }, [_vm._v("Owner")]), _c('ul', [_c('li', [_vm._v("Everything Admins can do")]), _c('li', [_vm._v("Add/remove admin, manager & support users")]), _c('li', [_vm._v("Transfer ownership to another user on the profile")]), _c('li', [_vm._v("Add/edit payment info")]), _c('li', [_vm._v("Delete profile (if profile has no active requests or bookings)")])]), _c('br'), _c('strong', {
    staticClass: "text-h6"
  }, [_vm._v("Admin")]), _c('ul', [_c('li', [_vm._v("Everything Managers can do")]), _c('li', [_vm._v("Add/remove manager & support users")]), _c('li', [_vm._v("Edit profile settings")])]), _c('br'), _c('strong', {
    staticClass: "text-h6"
  }, [_vm._v("Manager")]), _c('ul', [_c('li', [_vm._v("Everything Supporters can do")]), _c('li', [_vm._v("Add/remove support users")]), _c('li', [_vm._v("Open/close locations & spaces")]), _c('li', [_vm._v("Edit locations & spaces")]), _c('li', [_vm._v("Accept/reject bookings")])]), _c('br'), _c('strong', {
    staticClass: "text-h6"
  }, [_vm._v("Supporter")]), _c('ul', [_c('li', [_vm._v("Receive notifications of messages & bookings: requests, confirmations, and cancellations")]), _c('li', [_vm._v("Message on behalf of the profile")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }