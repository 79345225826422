<template>
  <v-dialog v-model="dialog" width="500">
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title class="py-2">{{ user ? 'Edit' : 'Add' }} user</v-card-title>
        <v-card-text>
          <vx-input v-model="email" type="email" label="Email" disabled required />
          <vx-input
            v-model="userInput.role"
            type="select"
            label="Role"
            item-text="name"
            item-value="_id"
            :items="rolesInput"
            :disabled="isRoleDisabled"
            required
          />

          <v-row>
            <v-col>
              <!-- <div>What notifications do you want to receive?</div> -->

              <!-- <v-checkbox label="Company updates" v-model="secondaryEmail.notification.company" /> -->
              <div><b>Communications</b></div>
              <v-checkbox v-model="userInput.notification" label="System notifications" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <vx-btn block color="tertiary" @click="dialog = false">Cancel</vx-btn>
            </v-col>

            <v-col cols="6" sm="6" md="6">
              <vx-btn block color="primary" :loading="loading" @click="$validate(updateUser)"> Save </vx-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { AccountService } from '@tutti/services';

export default {
  name: 'AccountAddEditEmail',
  props: {
    value: { type: Boolean, required: true },
    roles: { type: Array, required: true },
    user: { type: Object, required: false },
  },
  data() {
    return {
      loading: false,
      dialog: this.value,
      email: this.user?.user?.email,
      userInput: {
        user: this.user?.user?._id,
        role: this.user?.role?._id,
        notification: this.user?.notification,
      },
    };
  },
  computed: {
    isRoleDisabled() {
      return this.user?.role?.slug == 'owner';
    },
    rolesInput() {
      if (this.user?.role?.slug == 'owner') {
        return this.roles;
      } else {
        return this.roles.filter(role => role.slug != 'owner');
      }
    },
  },
  watch: {
    value(newValue) {
      this.dialog = newValue;
    },
    dialog(newValue) {
      this.$emit('input', newValue);
    },
  },
  methods: {
    async updateUser() {
      this.loading = true;

      const response = await AccountService.adminUpdateUser(this.userInput, this.$route.params.id);

      if (response) {
        this.dialog = false;
        this.$emit('change');
      }

      this.loading = false;
    },
  },
};
</script>
