<template>
  <v-card>
    <v-card-title
      >Learn about permissions on Tutti

      <v-icon large class="ml-auto" @click="$emit('close')"> mdi-close</v-icon>
    </v-card-title>

    <v-card-text>
      <strong class="text-h6">Owner</strong>
      <ul>
        <li>Everything Admins can do</li>
        <li>Add/remove admin, manager & support users</li>
        <li>Transfer ownership to another user on the profile</li>
        <li>Add/edit payment info</li>
        <li>Delete profile (if profile has no active requests or bookings)</li>
      </ul>

      <br />

      <strong class="text-h6">Admin</strong>
      <ul>
        <li>Everything Managers can do</li>
        <li>Add/remove manager & support users</li>
        <li>Edit profile settings</li>
      </ul>

      <br />

      <strong class="text-h6">Manager</strong>
      <ul>
        <li>Everything Supporters can do</li>
        <li>Add/remove support users</li>
        <li>Open/close locations & spaces</li>
        <li>Edit locations & spaces</li>
        <li>Accept/reject bookings</li>
      </ul>

      <br />

      <strong class="text-h6">Supporter</strong>
      <ul>
        <li>Receive notifications of messages & bookings: requests, confirmations, and cancellations</li>
        <li>Message on behalf of the profile</li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AboutPermissions',
};
</script>
