<template>
  <div>
    <section>
      <div class="d-flex">
        <div class="text-h6 my-auto">Users</div>
      </div>

      <UsersTable v-model="dataTable.user" @edit="addUser" @transfer="transferOwnership" @remove="removeUser" />
    </section>

    <br />

    <section>
      <div class="d-flex">
        <div class="text-h6 my-auto">Invitations</div>
        <div class="ml-auto my-2">
          <vx-btn outlined color="primary" @click="addInvitation()">Invite user</vx-btn>
        </div>
      </div>

      <InvitationsTable
        v-model="dataTable.invitation"
        @edit="addInvitation"
        @resend="resendInvitation"
        @remove="removeInvitation"
      />
    </section>

    <UserDialog
      v-if="dialog.user"
      v-model="dialog.user"
      :roles="roles"
      :user="selected.user"
      @change="getAccountUsers()"
    />

    <InvitationDialog
      v-if="dialog.invitation"
      v-model="dialog.invitation"
      :roles="roles"
      :invitation="selected.invitation"
      @change="getInvitations()"
    />

    <v-dialog v-model="dialog.permissions" width="600">
      <AboutPermissions @close="dialog.permissions = false" />
    </v-dialog>
  </div>
</template>

<script>
import { AccountService, RoleService, OnboardingService } from '@tutti/services';

import UsersTable from './data-tables/UsersTable';
import InvitationsTable from './data-tables/InvitationsTable';

import UserDialog from './dialogs/UserDialog';
import InvitationDialog from './dialogs/InvitationDialog';
import AboutPermissions from './AboutPermissions.vue';

export default {
  name: 'Permissions',
  components: {
    InvitationsTable,
    UsersTable,
    InvitationDialog,
    UserDialog,
    AboutPermissions,
  },

  props: {
    account: { type: Object, required: true },
  },

  data() {
    return {
      roles: [],
      dialog: {
        invitation: false,
        user: false,
        permissions: false,
      },
      selected: {
        invitation: null,
        user: null,
      },
      dataTable: {
        user: {
          key: 0,
          itemKey: '_id',
          selected: [],
          headers: [
            { text: 'Name', value: 'name', width: '30%', align: 'center' },
            { text: 'Email', value: 'email', width: '30%', align: 'center' },
            { text: 'Role', value: 'role', width: '10%', align: 'center' },
            { text: 'Notifications', value: 'notification', width: '10%', align: 'center' },
            { text: 'Actions', value: 'actions', width: '10%', align: 'center' },
          ],
        },
        invitation: {
          key: 0,
          itemKey: '_id',
          selected: [],
          headers: [
            { text: 'Email', value: 'email', width: '30%', align: 'center' },
            { text: 'Role', value: 'role', width: '20%', align: 'center' },
            { text: 'Status', value: 'status', width: '10%', align: 'center' },
            { text: 'Actions', value: 'actions', width: '20%', align: 'center' },
          ],
        },
      },
    };
  },
  async created() {
    await this.getRoles();
    await this.getAccountUsers();
    await this.getInvitations();
  },
  methods: {
    async getAccountUsers() {
      const response = await AccountService.getUsers(this.$route.params.id);
      if (response) {
        this.dataTable.user.items = response.data;
        this.dataTable.user.key++;
      }
    },
    async getInvitations() {
      const response = await OnboardingService.get(this.$route.params.id);
      if (response) {
        this.dataTable.invitation.items = response.data;
        this.dataTable.invitation.key++;
      }
    },
    async getRoles() {
      const response = await RoleService.get();
      if (response) {
        this.roles = response.data.data;
      }
    },
    addUser(item) {
      this.selected.user = item;
      this.dialog.user = true;
    },
    addInvitation(item) {
      this.selected.invitation = item;
      this.dialog.invitation = true;
    },
    async removeUser(item) {
      const confirm = await this.$confirm('REMOVE_USER');
      if (confirm) {
        const response = await AccountService.adminDeleteUser(item.user._id, this.$route.params.id);
        if (response) {
          this.getAccountUsers();
        }
      }
    },
    async removeInvitation(item) {
      const confirm = await this.$confirm('REMOVE_INVITATION');
      if (confirm) {
        const response = await OnboardingService.adminDelete(item._id);
        if (response) {
          this.getInvitations();
        }
      }
    },
    async transferOwnership(item) {
      const confirm = await this.$confirm('TRANSFER_OWNER');
      if (confirm) {
        item._loading = true;

        const response = await AccountService.adminTransferOwnership(item.user._id, this.account._id);
        if (response) {
          this.getAccountUsers();
        }

        item._loading = false;
      }
    },
    async resendInvitation(item) {
      if (item.status == 'pending') {
        item._loading = true;

        const response = await OnboardingService.adminInvite(
          {
            email: item.email,
            role: item.role._id,
          },
          this.$route.params.id
        );

        if (response) {
          // do something
        }

        item._loading = false;
      }
    },
  },
};
</script>
